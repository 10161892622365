
/***** tab css ***/

.irem__tabs-and-content{
  width: 100%;
.tab-list {
    height: 44px;
    display: flex;
    list-style: none;
    font-size: 14px;
    margin: 0 0.5rem;
    border-bottom:1px solid #e3e3e3;
    padding: 0;

  .tabs {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #333;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
  .active-tabs{
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0.1);
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 3px;
        background-color: $primary;
      }

  }

  }
  .active-tabs{
    & > button{
      background-color: $primary;
      color: #ffff;
      }
  }
  .content-container{
    width: 100%;
  }
  .content {
      display: none;
      // padding: 10px 0;
      // padding-bottom: 1.5rem;
  }

  .active-content {
      display: block;
      width: 100%;
  }
}

// kishan
.irem__tabs-and-content_parent{
  width: 100%;
  .tab-list {
    height: 44px;
    display: flex;
    list-style: none;
    font-size: 14px;
    margin: 0 0.5rem;
    border-bottom:1px solid #e3e3e3;
    padding: 0;

    background-color: #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid #f0f0f0;

    .tabs {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: #333;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      border-right: 1px solid #e3e3e3;
    }
    .active-tabs{
      background-color: #ffff;
      color: $primary;
      border-bottom: none;
      margin-bottom: -1px;
    }
  }
  .active-tabs{
    & > button{
      background-color: $primary;
      color: #ffff;
      }
  }
  .content-container{
    width: 100%;
  }
  .content {
      display: none;
      // padding: 10px 0;
      // padding-bottom: 1.5rem;
  }
  .active-content {
      display: block;
      width: 100%;
  }
}