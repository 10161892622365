// layout css

.app_main {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background-color: #f2f5f7;
    .irpm__sidebar {
      background-color: #143049;
      width: 200px;
      position: fixed;
      height: 100%;
      top: 0;
      left: 0;
      transition: all 0.6s ease-in;
      transition: opacity visibility 0.3s ease-in;
      z-index: 2;
    }
    .hide-menu{
        width: 0px;
        opacity: 0;
        visibility: hidden;
    }
    .app__wrapper {    
        position: relative;
        transition: all 0.5s linear;
        display: flex;
        flex-direction: column;
        .content__body{
          flex: 1;
        }
    }
    .wrapper_fix{
      width: calc(100% - 500px);
      margin-left: 200px;
    }
    .wrapper_expand{
      width: 85%;
      margin-left: 0px;
    }
  }
  // sidebar css
  .irpm__sidebar {
    .sidebar-logo-container {
      padding: 1rem 0;
      background-color: #10283e;
      display: flex;
      justify-content: center;
      .sidebar-logo-image {
        height: 50px;
        vertical-align: middle;
      }
    }
    .irpm__navbar {
      padding-left: 0;
      margin-top: 1rem;
      .nav-item {
        font-family: $font-family-default;
        list-style: none;
        .nav-link {
          display: flex;
          align-items: center;
          padding: 0.5rem 1rem;
          color: #949cb4;
          fill: #949cb4;
          border-radius: 10px;
          margin: 8px 15px;
          transition: all 0.3s ease;
          .nav-text {
            margin-left: 0.75rem;
            flex: 1;
            line-height: 30px;
          }
          &:hover,&.active{
              background-color: $primary;
              box-shadow: 0 4px 4px rgba(12, 93, 180, 0.25);
              color: #fff;
              fill: #fff;
          }
          .irpm__badge{
              background-color: $danger;
              color: #fff;
              padding: 0rem 0.5rem;
              border-radius: 4px;
              line-height: 22px;
          }
        }
      }
    }
  }
  // top header css
  .irpm__top-header{
      padding: 0.75rem 1rem;
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: 0 3px 3px rgba(56,65,74,0.1);
      z-index: 1;
      .user__logout{
      color: $text-dark;
      fill: $text-dark;
      }
      .toggler-icon{
          cursor: pointer;
      }
  }
  // footer
  
  .irpm__footer{
    padding: 0.75rem 1rem;
    background-color: #fff;
    box-shadow: 3px 0px 5px rgba(56,65,74,0.1);
  }

  .irpm__rightsidebar {
    height: 100%;
    width: 300px;
    right: 0;
    background-color: #fff;
    position: fixed!important;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 3px 3px rgba(56,65,74,0.1);
  }