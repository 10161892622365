body {
  font-family: $font-family;
  font-size: $font-default;
  color: $text-description;
  line-height: auto;
  margin: 0;
  background-color: #f2f5f7;
  padding: 0;
  font-weight: normal;
}
*,
::before,
::after {
  box-sizing: border-box;
}
h1,h2,h3,h4,h5,h6{
  font-family: $heading-font-family;
  font-weight: 600;
}
a {
  text-decoration: none;
}
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.items-end{
  align-items: flex-end;
}
.container_fluid {
  width: 100%;
  padding: 20px;
}
.fs-2{
  font-size: 1.25rem;
}
.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(5, 1fr);
}
.irpm__card {
  width: 100%;
  padding: 1rem;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(56, 65, 74, 0.1);
  .irpm__card-body {
    width: 100%;
    .card-title {
      font-size: 1rem;
      font-weight: 500;
      font-family: $font-family-default;
    }
  }
}
.mt-5 {
  margin-top: 1.5rem;
}
.ml-3 {
  margin-left: 1rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-family: $font-family-default;
  transition: all 0.3s ease;
  &.button-success {
    background-color: $green;
    color: #fff;
    &:hover {
      background-color: #34b070;
    }
  }
  &.button-default{
    background-color: #d9d9d9;
    color: #555;
    cursor: pointer;
    &:hover {
      background-color: #c1c1c1;
    }
  }
  &.button-primary {
    background-color: $primary;
    color: #fff;
    &:hover {
      background-color: #0062b9;
    }
  }
  &.button-danger {
    background-color: $danger;
    color: #fff;
    &:hover {
      background-color: #e43232;
    }
  }
  &.button-orange {
    background-color: rgba($color: $orange, $alpha: 1);
    color: #fff;
    &:hover {
      background-color: rgba($color: $orange, $alpha: 0.8);
    }
  }
  &:disabled,
  &.disabled {
    opacity: 0.65;
    cursor: default;
  }
  &.button-small {
    font-size: 0.875rem;
  }
  &.button-large {
    padding: 0.75rem 1rem;
    min-width: 120px;
  }
}
.button-outline-default {
  background-color: rgba($color: $primary, $alpha: 0.15);
  color: $primary;
  &:hover {
    background-color: rgba($color: $primary, $alpha: 1);
    color: #fff;
  }
}
.icon {
  padding: 0.5rem;
  margin: 0 5px;
  font-size: 1.125rem;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  line-height: normal;
  transition: all 0.3s ease;
  cursor: pointer;
  &.icon-info {
    background-color: rgba($color: $primary, $alpha: 0.2);
    color: $primary;
    &:hover {
      background-color: rgba($color: $primary, $alpha: 1);
      color: #fff;
    }
  }
  &.icon-orange {
    background-color: rgba($color: $orange, $alpha: 0.2);
    color: $orange;
    &:hover {
      background-color: rgba($color: $orange, $alpha: 1);
      color: #fff;
    }
  }
  &.icon-success {
    background-color: rgba($color: $teal, $alpha: 0.2);
    color: $teal;
    &:hover {
      background-color: rgba($color: $teal, $alpha: 1);
      color: #fff;
    }
  }
  &:disabled,
  &.disabled {
    opacity: 0.55;
    cursor: not-allowed;
  }
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
.w-50 {
  width: 50%;
}
@mixin k-dialog {
  max-width: 465px;
  width: 100%;
  border-radius: 6px;
}
@mixin footer {
  padding: 0.5rem;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  text-align: right;
  .button {
    margin: 0 5px;
  }
}

.irepm__common_modal_primary {
  .k-dialog {
    @include k-dialog;
  }
  .k-dialog-titlebar {
    background-color: $primary;
    color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.irepm__common_modal__delete {
  .k-dialog {
    @include k-dialog;
  }
  .k-dialog-titlebar {
    background-color: #fff;
    color: #555;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1.25rem;
  }
  .k-window-title {
    font-size: 1rem;
  }
  .k-dialog-title {
      font-size: 1.125rem;
      text-transform: uppercase;
      color: #222;
      font-weight: 600;
      font-family: $font-family-default;
    }
  .title {
    font-family: $font-family-default;
    font-weight: 600;
    line-height: normal;
  }
}
.icon-delete {
  svg {
    fill: #a3a3a3;
    width: 50px;
    height: 50px;
  }
}
.form__checkbox {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background-color: rgba($color: $primary, $alpha: 0.02);
  height: 20px;
  width: 20px;
  padding: 5px;
  margin: 10px 0;
  &:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  }
  &[type="checkbox"]:checked,
  [type="radio"]:checked {
    background-color: $primary;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-color: transparent;
  }
}
.check_label {
  margin-bottom: 0;
  margin-left: 10px;
  vertical-align: super;
}
// create modal
.irpm__common_modal-primary {
    padding: 2rem 0;
  .k-dialog-content {
    padding: 0;
    overflow: initial;
  }
  .k-dialog {
    width: 100%;
    border-radius: 6px;
    max-width: 1140px;
    max-height: 100%;
    @media (max-width: 768px) {
      margin: 0 3rem;
      max-width: 100%;
      width: 90%;
    }
  }
  .k-dialog-titlebar {
    background-color: rgba($color: $primary, $alpha: 0.1);
    color: #555;
    font-weight: 600;
    padding: 1rem;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .k-dialog-title {
      font-size: 1.125rem;
      text-transform: uppercase;
      color: #222;
      font-family: $font-family-default;
    }
  }
  .irpm__modal__body {
    padding: 1rem;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .irpm__modal-footer {
    @include footer;
  }
}

// form style

.input-form-label {
  font-family: $font-family-default;
  color: $text-description;
  font-weight: 400;
  margin-bottom: 3px;
}

.input_form-control {
  border: 1px solid #d9d9d9;
  appearance: none;
  display: block;
  width: 100%;
  color: #222;
  font-size: 0.875rem;
  border-radius: 6px;
  font-weight: 500;
  background-color: #fff;
  background-clip: padding-box;
  background-color: #f8f8f8;
  padding: 0.5rem;
  min-height: unset;
  box-shadow: none;
  &:focus,&:focus-within {
    border-color: none;
    outline: none;
    box-shadow: none;
  }
  &.is-dropdown{
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26297 6.88189 9.37357 6.83112 9.49543C6.78035 9.61729 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61729 17.2189 9.49543C17.1681 9.37357 17.0937 9.26297 17 9.17Z" fill="black"/></svg>');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  &.is-invalid {
    border: 1px solid $danger;
  }
}
.simple_form-control{
  border: 1px solid #d9d9d9;
  appearance: none;
  display: block;
  width: 100%;
  color: #222;
  border-radius: 0 6px 6px 0;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #fff;
  background-clip: padding-box;
  background-color: #f8f8f8;
  padding: 0.5rem;
  &:focus {
    border-color: $primary;
    outline: none;
    border-radius: 0 6px 6px 0;
  }
  &.is-invalid {
    border: 1px solid $danger;
  }
}
.text-danger {
  color: $danger;
}
.error-msg{
  color: $danger;
  margin-top: 0.5rem;
}


.user_email{
  input[type="text"]:disabled{
    background-color: #f0f0f0;
  }
}

/* activate toggle */
.accordion_item.active .button {
  background-color: #105057;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.answer_wrapper.open {
  height: 50px;
  margin-bottom: 1rem;
}

.checkbox.is-invalid {
  // border: 1px solid $danger;
  outline: 1px solid $danger;
}

.k-grid-header-wrap thead tr:nth-child(1) {
  background-color: #e0eaf4;
  color: #44749F;
}
.pn-msg-input__wrapper {
  align-items: center;
}
.call_modal .k-window-actions {
  display: none;
}
.profile-image-fallback{
  width: 50px;
  background: #e9e9e9;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .DateRangePicker {
//   position: absolute !important;
//   display: inline-block;
//   right: 60px;
//   top: 11px;
// }
/***** chat css ****/
// .rounded-full {
//   border-radius: 9999px;
// }

.videoDiv {
  height: 400px;
  max-height: 400px;
}
.videoEle {
  height: 100%;
  margin: 0 auto;
}

.icon-small {
  padding: 0.2rem;
  margin: 0 2px;
  font-size: 1.125rem;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  line-height: normal;
  transition: all 0.3s ease;
  cursor: pointer;
  &.icon-info {
    background-color: rgba($color: $primary, $alpha: 0.2);
    color: $primary;
    &:hover {
      background-color: rgba($color: $primary, $alpha: 1);
      color: #fff;
    }
  }
  &.icon-orange {
    background-color: rgba($color: $orange, $alpha: 0.2);
    color: $orange;
    &:hover {
      background-color: rgba($color: $orange, $alpha: 1);
      color: #fff;
    }
  }
  &.icon-success {
    background-color: rgba($color: $teal, $alpha: 0.2);
    color: $teal;
    &:hover {
      background-color: rgba($color: $teal, $alpha: 1);
      color: #fff;
    }
  }
}

.news-feed-title {
  text-transform: capitalize;
  color: #0072d8;
  text-align: center;
  font-weight: 700;
  margin: 14px 0;
}
.k-dialog-wrapper {
  width: calc(100% - 300px);
  padding: 0 20px;
}
.k-overlay {
  width:calc(100% - 300px);
}
.k-pager-md .k-pager-sizes .k-dropdown-list, .k-pager-md .k-pager-sizes .k-dropdown, .k-pager-md .k-pager-sizes .k-dropdownlist, .k-pager-md .k-pager-sizes > select {
  width: 6em;
}