.irpm__row {
    --bs-gutter-x: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    // width: 100%;
    // max-width: 100%;
    & > * {
        box-sizing: border-box;
        flex-shrink: 0;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
  }
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
  }
  
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  