.irpm__card {
  .irpm__card-body {
    .irpm__card-icons{
      padding: 10px;
      border-radius: 10px;
      height: 50px;
      width: 50px;
    }
    .irpm__card-heading {
      font-size: 1.375rem;
      font-weight: 600;
      font-family: $font-family-default;
      color: $text-dark;
    }
    .bottom_part{ 
      align-items: center;
      margin-bottom: 0.5rem;
      .irpm__card-data{
        font-size: 1.125rem;
        font-weight: 600;
        color: $text-dark;
      }
      .irspanm__card-lable{
        margin: 0;
      }
    }
    .border-top-dashed{
      margin: 1.5rem 0;
      border-top: 2px dashed;
      border-color: #d8d8d8;
    }
  }
}
