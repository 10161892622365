@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "variables";
@import "common";
@import "layout";
@import "dashboard";
@import "tasks";
@import "grid";
@import "tabs";
#root{
  height: 100%;
}
.irpm__accordion {
  list-style: none;
  padding-left: 0;
  .irpm__accordion_item {
    .irpm__accordion_item-button {
      width: 100%;
      text-align: left;
      margin-bottom: 1rem;
      background-color: #fff;
      color: $text-dark;
      padding: 0.5rem;
      border-radius: 6px;
      border: 1px solid rgba($color: $primary, $alpha: 0.15);
      display: flex;
      justify-content: space-between;
      &:focus {
        outline: none;
      }
    }
  }
}
.bg-yellow-mark{
    background-color:rgba($color: $primary, $alpha: 0.15) ;
    color: $text-dark;
    font-weight: 500;
}
.toppings-list-item{
  min-width: 176px;
}
.login-logo-image {
  width:150px;
  padding: 1rem;
  object-fit: contain;
}
.login-fold {
  position: relative;
  display: block;
  .login-image {
    position: relative;
    width: 100%;
    height: 340px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h4 {
    position: absolute;
    display: block;
    bottom: 0px;
    background-color: #3f65ba;
    color: #fff;
    padding: 10px 30px;
    text-transform: capitalize;
    font-size: 28px;
    font-weight: 600;
  }
}
.k-calendar-container, .k-datetime-container{
  z-index: 10008 !important;
}
.toaster{
  .toast-success,.toast-header{
    color: #fff !important;
    font-weight: 500;
  }
}
.css-l4ycov-control{
  min-height: 34px !important;
}
.css-tlfecz-indicatorContainer{
  padding: 4px 8px;
}
.btn-success {
  color: #fff;
}
  .k-datepicker,
  .k-timepicker,
  .k-textbox-container {
    width: 100%;
  }
  .k-dateinput-wrap {
    .k-input {
      padding: 0.45rem !important;
      height: auto;
      border-radius: 6px;
    }
    .k-dateinput .k-dateinput-wrap {
      border-radius: 6px;
    }
  }

table{
  min-width: 100% !important;
}

.k-animation-container {
  z-index: 10008 !important;
}
.k-tooltip {
  width: 40vh;
  height: 50px;
}
@mixin spandetail {
    position: relative;
    min-width: 100px;
    &::after{
      content: '';
      position: absolute;
      right: 0;
      top: 20%;
      bottom: 20%;
    }
    &:last-child{
      &::after{
        content: none;
      }
    }
}
.irpm__bg_timing{
  background-color: #FFF4EE;
  color: #e9883e;
  border-left: 2px solid #e9883e;
  span{
    @include spandetail;
    &::after{
      border-right: 1px solid #e9883e;
    }
  }
}

.irpm__bg_comment{
  background-color: #EDF5FF;
  color: #5da5e0;
  border-left: 2px solid #5da5e0;
  span{
    @include spandetail;
    &::after{
      border-right: 1px solid #5da5e0;
    }
  }
}
.irpm__bg_measurement{
  background-color: #FAEBFE;
  color: #bb58df;
  border-left: 2px solid #bb58df;
  span{
    @include spandetail;
    &::after{
      border-right: 1px solid #bb58df;
    }
  }
}
.rpm__timeline-title{
  font-size: 1.25rem;
  color: #e9883e;
  font-weight: 600;
  margin-bottom: 1rem;
}

.toaster {
  z-index: 10001;
}
.pn-member.active {
  background-color: #d9f2fc
}
.k-tooltip {
  height: 100%;
}
.desTextToolTip div {
  display: inline-block;
  font-size: 15px;
}
.login-box{
  height: 360px;
}
.small-modal div.k-dialog {
  width: 25%;
}

/**** alert ****/
.notification-container {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999
}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.notification {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 30px;
	margin-bottom: 15px;
	width: 300px;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
}

.notification:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.notification-title {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.notification-message {
	margin: 0;
	text-align: left;
  height: auto;
  margin-left: -1px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.notification-image {
    float: left;
    margin-right: 15px;
}

.notification-image svg {
    width: 30px;
    height: 30px;
}

.toast {
  width: 365px;
	color: #fff;
	padding: 15px;
}

.notification-container button {
	position: relative;
	right: -.3em;
	top: -.3em;
	float: right;
	font-weight: 700;
	color: #fff;
	outline: none;
	border: none;
	text-shadow: 0 1px 0 #fff;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);

	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);

	}
	to {
		transform: translateX(0);
	}
}

.DateRangePicker_picker{
  position: fixed  !important;
  top: unset !important;
  left: unset !important;
}

.k-column-title {
  font-weight: 550;
}

.table-header-bg-font {
  background: #E0EAF4;
  color: #44749F;
  font-weight: 550;
}
.table-font td {
  font-size: 13px;
  font-weight: 550;
}

.k-dateinput{
  padding: 0.2rem !important;
  height: auto;
  border-radius: 6px;
}

/*** preview image ***/
.PhotoView-Portal {
  z-index: 10003 !important;
}

/*** new tooltip ***/
.input-form-label-with-tooltip {
  display: flex;
  align-items: center;
  position: relative;
}

.tooltip-icon {
  margin-left: 8px; /* Adjust spacing as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.tooltip {
  position: absolute;
  top: 100%; /* Position below the label */
  left: 0; /* Adjust positioning as needed */
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin-top: 5px;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

