// Variable overrides

$orange: #fe7d41;
$yellow: #ffbe0b;
$green: #45CB85;
$teal: #25cdc2;
$danger : #f93838;
$blue : #0072d8;

$gray-base: #3a3a3a !default;
$form-feedback-valid-color: #75c21e !default;
$sidebar-dark-nav-link-icon-color: white !default;
$high-emphasis-inverse: white !default;

// new variables

$font-family : "Roboto";
$font-family-default : "Poppins";
$font-default : 0.875rem;
$text-description :  #6c6e71;
$text-dark : #343a40;

$heading-font-family : $font-family-default;

$primary : $blue;
$secondary: $teal;
$danger : $danger;
$success : $green;
$warning : $yellow;