.irpm__table-task {
  .k-grid-toolbar {
    justify-content: flex-end;
    gap: 0;
  }
}
.irpm__task-grid-table{
  table{
    width: 100% !important;
  }
}